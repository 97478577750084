<template>
  <div class="general-information--wrap">
    <h1 class="mb-6">
      {{ $t('information.general') }}
    </h1>

    <div class="mb-6">
      <v-btn
        v-if="charterUrl"
        :href="charterUrl"
        target="_blank"
        depressed
        color="primary"
        class="mr-2"
      >
        {{ $t('information.currentCharterDownload') }}
      </v-btn>

      <v-btn
        v-if="agreementUrl"
        :href="agreementUrl"
        target="_blank"
        depressed
        color="primary"
      >
        {{ $t('information.conditionsDownload') }}
      </v-btn>
    </div>

    <document-table
      :search="{ displayAreas: ['general'] }"
      :title="$t('information.generalHint')"
    />
  </div>
</template>

<script>
import Api from '@/api/Api'
import AuthApi from '@/api/Auth'
import DocumentApi from '@/api/Documents'
import DocumentTable from '@/components/DocumentTable'

export default {
  name: 'general-information',

  components: {
    DocumentTable,
  },

  data () {
    return {
      conditions: null,
      charterUrl: null,
    }
  },

  computed: {
    agreementUrl () {
      return this.conditions
        ? Api.getAgreementUrl(this.conditions.agreementId, this.$i18n.locale)
        : null
    },
  },

  mounted () {
    this.getCurrentCharterUrl()
    this.getConditionsOfParticipation()
  },

  methods: {
    /**
     * Loads the current charter and creates a download-url for it.
     *
     * @returns {Promise}
     */
    async getCurrentCharterUrl () {
      const res = await DocumentApi.getCurrentCharter()

      if (res.ok) {
        this.charterUrl = DocumentApi.getDownloadLink(await res.json())
      }
    },

    /**
     * Loads the agreements, tries to find the current conditions and creates
     * a download-url for it.
     *
     * @returns {Promise}
     */
    async getConditionsOfParticipation () {
      const res = await AuthApi.getAgreements()

      if (!res.ok) {
        return
      }

      const agreements = await res.json()
      this.conditions = agreements.find(agreement => agreement.key === 'conditions')
    },
  },
}
</script>
