<template>
  <div class="user-status-pie--wrap">
    <donut-base
      :height="height"
      :option-changes="optionChanges"
      :series="series"
      :labels="labels"
      :loading="loading"
      :title="$t('shareholders.stocksPerGroup')"
    />
  </div>
</template>

<script>
import DonutBase from '@/components/charts/DonutBase'
import ShareholderFormatter from '@/services/shareholder-formatter'

export default {
  name: 'user-status-pie',

  components: {
    DonutBase,
  },

  props: {
    // height of the chart
    height: {
      type: Number,
      default: 450,
    },

    // show a loading-indiccator
    loading: {
      type: Boolean,
      default: false,
    },

    // all shareholders
    shareholders: {
      type: Array,
      default: () => ([]),
    },
  },

  computed: {
    /**
     * Amount of stocks grouped by shareholder's account-status.
     *
     * @returns {array}
     */
    stocksByShareholderStatus () {
      const shareholdersByStatus = ShareholderFormatter.groupShareholders(this.shareholders, 'accountStatus')

      if (Array.isArray(shareholdersByStatus.organe)) {
        shareholdersByStatus.mitarbeiter = [...(shareholdersByStatus.mitarbeiter || []), ...shareholdersByStatus.organe]
        delete shareholdersByStatus.organe
      }

      return Object.keys(shareholdersByStatus)
        .map(userGroup => ({
          userGroup,
          amount: shareholdersByStatus[userGroup].reduce((total, entry) =>
            total + entry.shares
          , 0)
        }))
        .filter(group => group.amount > 0)
    },

    /**
     * Chart-data to use: List of stock-amounts per shareholder-group.
     *
     * @returns {array}
     */
    series () {
      return this.stocksByShareholderStatus.map(group => group.amount)
    },

    /**
     * Chart-labels to use: Translatable list of shareholder-groups.
     *
     * @returns {array}
     */
    labels () {
      return this.stocksByShareholderStatus.map(group => this.$t(`status.${group.userGroup}`))
    },

    /**
     * Options of the chart-baseclass to change.
     *
     * @returns {object}
     */
    optionChanges () {
      return {
        dataLabels: {
          formatter: value => `${this.$options.filters.localeNumber(value, this.$i18n.locale, true)}%`
        },

        plotOptions: {
          pie: {
            donut: {
              labels: {
                total: {
                  label: this.$t('shareholders.totalStockAmount'),
                },
              },
            },
          },

          tooltip: {
            y: {
              formatter: value => this.$options.filters.localeNumber(value, this.$i18n.locale)
            },
          },
        },
      }
    },
  },
}
</script>
