<template>
  <div class="request-offer--wrap">
    <v-form ref="form" v-model="formIsValid" @submit.prevent="onSubmit">
      <v-card>
        <v-card-text class="py-6">
          <v-row>
            <v-col :cols="12" :md="6">
              <price-input v-model="request.price" :label="$t('sell.maxPrice')" />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field
                v-model.number="request.amount"
                prepend-inner-icon="mdi-counter"
                type="number"
                steps="1"
                :min="0"
                :rules="[...requiredRules, v => v >= 0]"
                :label="$t('sell.stockAmount')"
                hide-details
                outlined
              />
              <!--
                Thanks to the slider decimal places get removed (by rounding), positive values are enforced without
                further handling by us. It has been decided that we use a maximum since we don't want to handle
                floating issues, scientific representations.
              -->
              <v-slider v-model="request.amount" :max="1000000" class="d-none" />
            </v-col>
          </v-row>

          <v-row class="mb-4">
            <v-col :cols="12" :md="6">
              <date-text-input
                :value="request.expires | isoDate"
                :label="$t('sell.activeUntil')"
                :allowed-dates="allowedDates"
                :rules="requiredRules"
                @input="isoDate => request.expires = new Date(isoDate)"
              />
            </v-col>
            <v-col :cols="12" :md="6">
              <time-text-input
                :value="request.expires"
                :label="$t('sell.activeUntil')"
                @input="date => request.expires = date"
              />
            </v-col>
          </v-row>

          <v-alert color="secondary" outlined icon="mdi-information" dark border="left" class="mb-6">
            <div>
              {{ $t('sell.maxExpenses') }} <strong>{{ request.amount * request.price | readablePrice($i18n.locale) }}</strong>
            </div>
            <small>{{ $t('sell.revenuePreviewHint') }}</small>
          </v-alert>

          <v-switch
            v-model="request.boundToConfiguration"
            hide-details
            inset
          >
            <template #label>
              {{ $t('sell.boundToConfiguration') }}
            </template>
          </v-switch>

          <v-switch
            :input-value="!request.anonymous"
            hide-details
            inset
            @change="active => request.anonymous = !active"
          >
            <template #label>
              {{ $t('sell.createNotAnonymous') }}
            </template>
          </v-switch>

          <v-switch
            v-model="readTerms"
            :rules="requiredRules"
            hide-details
            inset
          >
            <template #label>
              <accepted-terms-label />
            </template>
          </v-switch>
        </v-card-text>

        <v-divider />

        <v-card-actions class="px-4">
          <v-btn v-if="toEdit" depressed @click="$emit('cancel')">
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('common.cancel') }}
          </v-btn>

          <v-spacer />

          <v-btn depressed type="submit" color="primary" :disabled="!formIsValid">
            <v-icon left>
              mdi-offer
            </v-icon>
            <span v-if="toEdit">{{ $t('sell.editOffer') }}</span>
            <span v-else>{{ $t('sell.newRequest') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <confirm-dialog
      :headline="toEdit ? $t('sell.updateRequest') : $t('sell.createRequest')"
      info-text=""
      :action-text="$t('common.confirm')"
      action-icon="mdi-check"
      action-color="primary"
      :is-visible="showConfirmation"
      @cancel="showConfirmation = false"
      @ok="saveRequest"
    >
      <offer-summary :amount="request.amount" :price="request.price" is-request />

      <v-form @submit.prevent="saveRequest">
        <v-text-field
          v-model="request.secondFactorCode"
          prepend-inner-icon="mdi-key"
          :label="$t('salesRequests.twoFactorInfo')"
          hide-details
          outlined
          autofocus
          class="pa-0 mt-6 mb-2"
        />
      </v-form>
    </confirm-dialog>
  </div>
</template>

<script>
import { add } from 'date-fns'

import AcceptedTermsLabel from '@/components/AcceptedTermsLabel'
import ConfirmDialog from '@/components/ConfirmDialog'
import DateTextInput from '@/components/DateTextInput'
import OfferApi from '@/api/Offer'
import OfferSummary from './OfferSummary'
import PriceInput from '@/components/PriceInput'
import TimeTextInput from '@/components/TimeTextInput'

const defaultRequest = () => ({
  amount: 0,
  anonymous: true,
  boundToConfiguration: true,
  expires: add(new Date(), { days: 1 }),
  offerType: 'buy',
  price: null,
  secondFactorCode: '',
})

export default {
  name: 'request-offer',

  components: {
    AcceptedTermsLabel,
    ConfirmDialog,
    DateTextInput,
    OfferSummary,
    PriceInput,
    TimeTextInput,
  },

  props: {
    toEdit: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      formIsValid: true,
      readTerms: false,
      showConfirmation: false,
      request: defaultRequest(),
    }
  },

  computed: {
    requiredRules () {
      return [v => !!v || this.$t('common.requiredField')]
    },
  },

  watch: {
    toEdit () {
      this.setRequest()
    }
  },

  mounted () {
    this.setRequest()
  },

  methods: {
    allowedDates (date) {
      return new Date(date) > new Date()
    },

    /**
     * Creates/updates the current offer, resets the state.
     *
     * @returns {void}
     */
    async saveRequest () {
      this.showConfirmation = false

      const res = this.toEdit
        ? await OfferApi.update(this.request)
        : await OfferApi.create(this.request)

      if (!res.ok) {
        this.request.secondFactorCode = ''

        return this.$store.commit('setSnackbar', {
          text: OfferApi.isSecondFactorError(res) ? this.$t('login.twoFactorError') : this.$t('common.errorOccured'),
          color: 'error',
        })
      }

      this.readTerms = false
      this.request = defaultRequest()
      this.$refs.form.resetValidation()

      if (this.toEdit) {
        this.$emit('updated:offer')
        return this.$store.commit('setSnackbar', { text: this.$t('common.updated') })
      }

      this.$store.commit('setSnackbar', { text: this.$t('common.created') })
      this.$router.push({ name: 'UserOffers' })
    },

    /**
     * Opens the confirmation-dialog if the entered data is valid.
     *
     * @returns {void}
     */
    onSubmit () {
      this.$refs.form.validate()

      this.formIsValid
        ? (this.showConfirmation = true)
        : this.$store.commit('setSnackbar', { text: this.$t('common.pleaseCorrect'), color: 'error' })
    },

    /**
     * Uses the request given as a prop instead of the empty default so the
     * user is able to edit the dataset.
     *
     * @returns {void}
     */
    setRequest () {
      if (this.toEdit !== null) {
        this.request = {
          ...this.toEdit,
          expires: new Date(this.toEdit.expires),
          secondFactorCode: '',
        }
      }
    },
  },
}
</script>
