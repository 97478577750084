var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-offers--wrap"},[_c('h1',{staticClass:"mb-6"},[_vm._v(" "+_vm._s(_vm.$t('sidebar.myOffers'))+" ")]),_c('data-table',{attrs:{"columns":_vm.columns,"items":_vm.offers,"title":_vm.$t('sell.myOffers'),"show-search":false},scopedSlots:_vm._u([{key:"headerActions",fn:function(){return [_c('v-btn',{attrs:{"to":{ name: 'OfferCreation' },"depressed":"","color":"secondary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-offer ")]),_vm._v(" "+_vm._s(_vm.$t('sidebar.offerCreation'))+" ")],1)]},proxy:true},{key:"item.offerType",fn:function(ref){
var item = ref.item;
return [_c('icon-buy-sell',{attrs:{"type":item.offerType}}),_vm._v(" "+_vm._s(_vm.$t(("sell." + (item.offerType))))+" ")]}},{key:"item.expires",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.expires,true))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readablePrice")(item.price,_vm.$i18n.locale))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('table-button',{attrs:{"icon":"mdi-cancel","icon-color":"red","tooltip":_vm.$t('sell.cancelOffer')},on:{"click":function($event){_vm.toDelete = item.offerId}}}),_c('table-button',{attrs:{"disabled":new Date(item.expires) < new Date(),"icon":"mdi-pencil","icon-color":"primary","tooltip":new Date(item.expires) < new Date() ? _vm.$t('sell.editPastNotAllowed') : _vm.$t('sell.editOffer')},on:{"click":function($event){_vm.toEdit = item}}})]}}],null,true)}),_c('pending-transactions',{staticClass:"mt-10"}),_c('confirm-dialog',{attrs:{"headline":_vm.$t('sell.cancelOffer'),"info-text":_vm.$t('sell.confirmCancel'),"is-visible":_vm.toDelete !== null},on:{"cancel":function($event){_vm.toDelete = null},"ok":_vm.cancelOffer}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.cancelOffer.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"pa-0 mt-6 mb-2",attrs:{"prepend-inner-icon":"mdi-key","label":_vm.$t('salesRequests.twoFactorInfo'),"hide-details":"","outlined":"","autofocus":""},model:{value:(_vm.secondFactorCode),callback:function ($$v) {_vm.secondFactorCode=$$v},expression:"secondFactorCode"}})],1)],1),_c('v-dialog',{attrs:{"value":_vm.toEdit !== null,"width":900,"max-width":"90%"},on:{"input":function (visibility) { return !visibility && (_vm.toEdit = null); }}},[_c('v-sheet',[(_vm.toEdit && _vm.toEdit.offerType === 'buy')?_c('request-offer',{attrs:{"to-edit":_vm.toEdit},on:{"cancel":function($event){_vm.toEdit = null},"updated:offer":_vm.onOfferUpdate}}):_vm._e(),(_vm.toEdit && _vm.toEdit.offerType === 'sell')?_c('sell-offer',{staticClass:"pa-6",attrs:{"to-edit":_vm.toEdit},on:{"updated:offer":_vm.onOfferUpdate}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }