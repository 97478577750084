import roles from '@/roles'
import store from '@/store'

import AnnualMeetings from '@/views/Meetings/AnnualMeetings'
import Approval from '@/views/Extras/Approval'
import ChangePassword from '@/views/User/ChangePassword'
import CmsDocuments from '@/views/Extras/Cms/Documents/Overview'
import CmsIndex from '@/views/Extras/Cms/Index'
import CompanyData from '@/views/Extras/CompanyData'
import ConfigurationOverview from '@/views/Extras/Configuration/ConfigurationOverview'
import CsvHandling from '@/views/Extras/CsvHandling'
import Dashboard from '@/views/Dashboard'
import DataConfidentiality from '@/views/Meta/DataConfidentiality'
import DateConfig from '@/views/Extras/DateConfig'
import Dates from '@/views/Dates/Dates'
import DividendPayout from '@/views/Extras/DividendPayout/Index'
import DocumentSearch from '@/views/Information/DocumentSearch'
import EmailsIndex from '@/views/Extras/Emails/Index'
import EmailTemplates from '@/views/Extras/Emails/Templates'
import ExtraIndex from '@/views/Extras/Index'
import GeneralInformation from '@/views/Information/General'
import InformationMeetings from '@/views/Meetings/InformationMeetings'
import InformationIndex from '@/views/Information/Index'
import MailsSent from '@/views/Extras/Emails/MailsSent'
import Management from '@/views/Information/Management'
import MeetingIndex from '@/views/Meetings/Index'
import MissingTranslations from '@/views/Extras/Translations/Missing'
import News from '@/views/Information/News/News'
import Notifications from '@/views/Notifications/Overview'
import PhantomsIndex from '@/views/Stocks/Phantoms/Index'
import PhantomsList from '@/views/Stocks/Phantoms/List'
import PhantomsTransactions from '@/views/Stocks/Phantoms/Transactions'
import Reports from '@/views/Information/Reports'
import StocksIndex from '@/views/Stocks/Index'
import RequestOffer from '@/views/Stocks/UserOffers/Creation/RequestOffer'
import Requests from '@/views/Stocks/SalesRequests/Requests'
import Sales from '@/views/Stocks/SalesRequests/Sales'
import SalesRequests from '@/views/Stocks/SalesRequests/Index'
import SellOffer from '@/views/Stocks/UserOffers/Creation/SellOffer'
import Shareholders from '@/views/Stocks/Shareholders'
import StockDistribution from '@/views/Stocks/Distribution'
import OfferCreation from '@/views/Stocks/UserOffers/Creation/Index'
import Transactions from '@/views/Stocks/Transactions/TransactionOverview'
import TranslationIndex from '@/views/Extras/Translations/Index'
import TranslationOverview from '@/views/Extras/Translations/Overview'
import UserDocuments from '@/views/UserDocuments'
import UserMails from '@/views/Extras/Emails/UserMails'
import UserOffers from '@/views/Stocks/UserOffers/OfferOverview'
import UserProfile from '@/views/User/Profile'
import UsersIndex from '@/views/Users/Index'
import UserStocks from '@/views/Stocks/UserStocks/Overview'
import UsersAddUser from '@/views/Users/AddUser'
import UsersEditUser from '@/views/Users/EditUser'
import UsersList from '@/views/Users/List'
import UserTransactions from '@/views/Stocks/Transactions/UserTransactions'

const privateRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
  },
  {
    path: '/user-documents',
    name: 'UserDocuments',
    component: UserDocuments,
    meta: {
      allowedRoles: [
        roles.user,
        roles.ehemaliger,
        roles.phantom,
        roles.management,
        roles.sellout,
        roles.exporter,
        roles.guest,
        roles.organe,
        roles.editor,
        roles.superadmin,
      ]
    },
  },
  {
    path: '/user-mails',
    name: 'UserMails',
    component: UserMails,
    meta: {
      allowedRoles: [
        roles.user,
        roles.ehemaliger,
        roles.phantom,
        roles.exporter,
        roles.guest,
        roles.organe,
        roles.editor,
        roles.superadmin,
      ]
    },
  },
  {
    path: '/meetings',
    name: 'Meetings',
    component: MeetingIndex,
    redirect: {
      name: 'AnnualMeetings'
    },
    children: [
      {
        path: 'annual',
        name: 'AnnualMeetings',
        component: AnnualMeetings,
      },
      {
        path: 'information',
        name: 'InformationMeetings',
        component: InformationMeetings,
      },
    ],
  },
  {
    path: '/dates',
    name: 'Dates',
    component: Dates,
  },
  {
    path: '/phantoms',
    name: 'Phantoms',
    component: PhantomsIndex,
    meta: {
      allowedRoles: [roles.management, roles.organe, roles.editor, roles.superadmin]
    },
    redirect: {
      name: 'PhantomsList'
    },
    children: [
      {
        path: 'list',
        name: 'PhantomsList',
        component: PhantomsList,
      },
      {
        path: 'transactions/:id?',
        name: 'PhantomsTransactions',
        component: PhantomsTransactions,
      },
    ],
  },
  {
    path: '/users',
    name: 'Users',
    component: UsersIndex,
    meta: {
      allowedRoles: [roles.editor, roles.superadmin]
    },
    redirect: {
      name: 'UsersList'
    },
    children: [
      {
        path: 'list',
        name: 'UsersList',
        component: UsersList,
      },
      {
        path: 'add',
        name: 'UsersAddUser',
        component: UsersAddUser,
      },
      {
        path: 'edit/:id',
        name: 'UsersEditUser',
        component: UsersEditUser,
      },
    ],
  },
  {
    path: '/stocks',
    name: 'Stocks',
    component: StocksIndex,
    redirect: {
      name: 'Transactions'
    },
    children: [
      {
        path: 'transactions/:filter?',
        name: 'Transactions',
        component: Transactions,
        meta: {
          allowedRoles: [roles.management, roles.editor, roles.organe, roles.superadmin]
        },
      },
      {
        path: 'user-transactions',
        name: 'UserTransactions',
        component: UserTransactions,
        meta: {
          allowedRoles: [
            roles.user,
            roles.organe,
            roles.exporter,
            roles.editor,
            roles.superadmin,
            roles.ehemaliger,
          ]
        },
      },
      {
        path: 'user-stocks',
        name: 'UserStocks',
        component: UserStocks,
        meta: {
          allowedRoles: [
            roles.guest,
            roles.ehemaliger,
            roles.phantom,
            roles.user,
            roles.organe,
            roles.exporter,
            roles.editor,
            roles.superadmin,
          ]
        },
      },

      {
        path: 'user-offers',
        name: 'UserOffers',
        component: UserOffers,
        meta: {
          allowedRoles: [roles.exporter, roles.organe, roles.editor, roles.user, roles.superadmin]
        },
      },

      {
        path: 'shareholders',
        name: 'Shareholders',
        component: Shareholders,
        meta: {
          allowedRoles: [
            roles.editor,
            roles.management,
            roles.organe,
            roles.superadmin,
          ]
        },
      },
      {
        path: 'sales-requests',
        name: 'SalesRequests',
        component: SalesRequests,
        meta: {
          allowedRoles: [roles.exporter, roles.organe, roles.editor, roles.user, roles.superadmin]
        },
        redirect: {
          name: 'Sales'
        },
        children: [
          {
            path: 'sales',
            name: 'Sales',
            component: Sales,
          },
          {
            path: 'requests',
            name: 'Requests',
            component: Requests,
          },
        ]
      },
      {
        path: 'create',
        name: 'OfferCreation',
        component: OfferCreation,
        meta: {
          allowedRoles: [roles.exporter, roles.organe, roles.editor, roles.user, roles.superadmin]
        },
        redirect: {
          name: 'CreateOffer'
        },
        children: [
          {
            path: 'offer',
            name: 'CreateOffer',
            component: SellOffer,
          },
          {
            path: 'request',
            name: 'CreateRequest',
            component: RequestOffer,
          },
        ],
      },
      {
        path: 'phantoms',
        name: 'PhantomsIndex',
        component: PhantomsIndex,
        meta: {
          allowedRoles: [
            roles.ehemaliger,
            roles.phantom,
            roles.user,
            roles.organe,
            roles.exporter,
            roles.editor,
            roles.superadmin,
          ]
        },
      },
      {
        path: 'stock-distribution',
        name: 'StockDistribution',
        component: StockDistribution,
        meta: {
          allowedRoles: [
            roles.user,
            roles.organe,
            roles.exporter,
            roles.editor,
            roles.superadmin,
          ]
        }
      },
    ],
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile,
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
  },
  {
    path: '/data-confidentiality',
    name: 'DataConfidentiality',
    component: DataConfidentiality,
  },
  {
    path: '/information',
    name: 'Information',
    component: InformationIndex,
    redirect: {
      name: 'GeneralInformation'
    },
    children: [
      {
        path: 'news',
        name: 'News',
        component: News,
      },
      {
        path: 'general',
        name: 'GeneralInformation',
        component: GeneralInformation,
      },
      {
        path: 'reports',
        name: 'Reports',
        component: Reports,
      },
      {
        path: 'document-search',
        name: 'DocumentSearch',
        component: DocumentSearch,
      },
      {
        path: 'management',
        name: 'Management',
        component: Management,
      },
    ],
  },
  {
    path: '/extras',
    name: 'Extras',
    component: ExtraIndex,
    children: [
      {
        path: 'approval',
        name: 'Approval',
        component: Approval,
        meta: {
          allowedRoles: [roles.editor, roles.management, roles.superadmin]
        },
      },
      {
        path: 'cms',
        name: 'CMS',
        component: CmsIndex,
        meta: {
          allowedRoles: [roles.editor, roles.superadmin]
        },
        redirect: {
          name: 'CmsDocuments'
        },
        children: [
          {
            path: 'documents',
            name: 'CmsDocuments',
            component: CmsDocuments,
          },
        ],
      },
      {
        path: 'emails',
        name: 'Emails',
        component: EmailsIndex,
        redirect: {
          name: 'EmailTemplates'
        },
        children: [
          {
            path: 'templates',
            name: 'EmailTemplates',
            component: EmailTemplates,
            meta: {
              allowedRoles: [roles.editor, roles.superadmin]
            },
          },
          {
            path: 'sent',
            name: 'MailsSent',
            component: MailsSent,
            meta: {
              allowedRoles: [roles.editor, roles.superadmin]
            },
          },
        ],
      },
      {
        path: 'company-data',
        name: 'CompanyData',
        component: CompanyData,
        meta: {
          allowedRoles: [roles.editor, roles.superadmin]
        },
      },
      {
        path: 'translations',
        name: 'Translations',
        component: TranslationIndex,
        meta: {
          allowedRoles: [roles.editor, roles.superadmin]
        },
        redirect: {
          name: 'TranslationOverview'
        },
        children: [
          {
            path: 'overview',
            name: 'TranslationOverview',
            component: TranslationOverview,
          },
          {
            path: 'missing',
            name: 'MissingTranslations',
            component: MissingTranslations,
          },
        ]
      },
      {
        path: 'configuration',
        name: 'Configuration',
        component: ConfigurationOverview,
        meta: {
          allowedRoles: [roles.editor, roles.superadmin]
        },
      },
      {
        path: 'csv_handling',
        name: 'CsvHandling',
        component: CsvHandling,
        meta: {
          allowedRoles: [roles.editor, roles.superadmin]
        },
      },
      {
        path: 'dates',
        name: 'DateConfig',
        component: DateConfig,
        meta: {
          allowedRoles: [roles.editor, roles.superadmin]
        },
      },
      {
        path: 'dividend_payout',
        name: 'DividendPayout',
        component: DividendPayout,
        meta: {
          allowedRoles: [roles.editor, roles.superadmin]
        },
      },
    ],
  }
]

/**
 * Applies guards to the private routes, so they dont't get accessed by
 * unauthicated or unautherized users.
 */
const guardedRoutes = privateRoutes.map(route => ({
  ...route,
  beforeEnter: (to, from, next) => {
    const user = store.state.user.user

    if (user === null) {
      return next({ name: 'Login', params: { target: to.fullPath } })
    }

    next()
  }
}))

export default guardedRoutes
