<template>
  <div class="phantom-transactions--wrap">
    <data-table
      :columns="columns"
      :custom-page-size="25"
      :items="phantomTransactions"
      :title="$t('phantoms.myTransactions')"
      :default-options="{ sortBy: ['datePerformed'], sortDesc: [true] }"
      class="tablePhantomTransactions"
    >
      <template #[`item.datePerformed`]="{ item }">
        {{ item.datePerformed | readableIsoDate }}
      </template>

      <template #[`item.type`]="{ item }">
        <transaction-type-chip :transaction-type="item.amount > 0 ? 'buy' : 'sell'" />
      </template>

      <template #[`item.amount`]="{ item }">
        {{ item.amount | localeNumber($i18n.locale) }}
      </template>
    </data-table>
  </div>
</template>

<script>
import PhantomApi from '@/api/Phantom'

import DataTable from '@/components/DataTable'
import TransactionTypeChip from '@/components/TransactionTypeChip'

export default {
  name: 'phantom-transactions',

  components: {
    DataTable,
    TransactionTypeChip,
  },

  data () {
    return {
      phantomTransactions: [],
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('common.amount'), value: 'amount', align: 'right', width: 150 },
        { text: this.$t('common.type'), value: 'type', width: 150 },
        { text: this.$t('common.date'), value: 'datePerformed' },
      ]
    }
  },

  mounted () {
    this.getUserPhantomTransactions()
  },

  methods: {
    /**
     * Loads the phantom-transactions of the current user.
     *
     * @returns {Promise}
     */
    async getUserPhantomTransactions () {
      const res = await PhantomApi.getMy()
      res.ok && (this.phantomTransactions = await res.json())
    },
  },
}
</script>
